export function SidebarMenuItems($t) {
  return {
    Main_data: {
      name: $t('Global.sideMenu_homedata'),
      icon: 'icon-navigation-supplier',
      route: '',
      children: [],
      showChildren: false,
      id: 'button-sidebar-meta-data-link',
      role: ''
    },
    Buy: {
      name: $t('Global.sideMenu_purchase'),
      icon: 'icon-navigation-price-quote',
      route: '',
      children: [],
      showChildren: false,
      id: 'button-sidebar-buy-link',
      role: ''
    },
    Cost: {
      name: $t('Global.sideMenu_costControl'),
      route: '',
      icon: 'icon-navigation-despatch',
      children: [],
      showChildren: false,
      id: 'button-sidebar-cost-link',
      role: ''
    },
    Stock: {
      name: $t('Global.sideMenu_StockAction'),
      route: '',
      icon: 'icon-navigation-onhand',
      children: [],
      showChildren: false,
      id: 'button-sidebar-stock-link',
      role: ''
    },
    Governance: {
      name: $t('Global.sideMenu_Governance'),
      route: '',
      icon: 'icon-navigation-governance',
      children: [],
      showChildren: false,
      id: 'button-sidebar-governance-link',
      role: ''
    }
  }
}
